body {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

input,
textarea {
  font-family: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}

button {
  margin: 0;
  border: 0;
  font-family: inherit;
  background-color: transparent;
  appearance: none;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding-left: 1em;
}
